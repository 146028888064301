import Typography from "components/Typography/Typography";
import { ReactElement } from "react";

import LinkImg from "assets/img/LinkImg.svg";
import styles from "./Link.module.css";

type LinkProps = {
  href: string;
  text: string;
};

function Link({ href, text }: LinkProps): ReactElement {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={styles.hrefStyle}>
      <Typography variant="body2Bold">{text}</Typography>
      <img id="linkImg" src={LinkImg} alt="linkImg" />
    </a>
  );
}
export default Link;
