import { ReactElement, ReactNode } from "react";
import styles from "./Typography.module.css";

type TypographyProps = {
  variant: "body1" | "body1variant" | "body1Bold" | "body1BoldBis" | "body2" | "body2Bold";
  children: ReactNode;
};

function Typography({ variant, children }: TypographyProps): ReactElement {
  return <span className={styles[variant]}>{children}</span>;
}
export default Typography;
