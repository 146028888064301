import { ReactElement } from "react";
import { Error } from "components";
import { ReferenceService } from "services";
import { ErrorDataProps } from "types";
import OguryLogo from "assets/img/Logo.svg";
import style from "./ErrorPage.module.scss";

function ErrorPage({ error }: { error: ErrorDataProps }): ReactElement {
  function computeShowLogo() {
    if (error.type === ReferenceService.errorType.INTERNAL || error.type === ReferenceService.errorType.AD_BLOCKER_ON) {
      return style.hideIfDesktop;
    }
    return null;
  }

  return (
    <div className={style.container}>
      <div className={`${style.logo} ${computeShowLogo()}`}>
        <img id="logo" src={OguryLogo} alt="Ogury logo" />
      </div>
      <div className={style.body}>
        <Error error={error} />
      </div>
    </div>
  );
}
export default ErrorPage;
