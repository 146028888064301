import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "context/app-provider";
import { ErrorMonitoringService } from "services";
import App from "./App";

const { REACT_APP_DATA_DOG_APP_ID, REACT_APP_DATA_DOG_CLIENT_TOKEN, REACT_APP_ENV, REACT_APP_RELEASE_ID } = process.env;
const errorMonitoring = new ErrorMonitoringService();
if (REACT_APP_ENV && REACT_APP_ENV !== "localhost") {
  errorMonitoring.init({
    applicationId: REACT_APP_DATA_DOG_APP_ID,
    clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
    env: REACT_APP_ENV,
    version: REACT_APP_RELEASE_ID,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <AppProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppProvider>
);
