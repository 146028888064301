import { useEffect, useState } from "react";
import { ScreenUtil } from "utils";
import style from "style/Variables";

export default function useResizeDetection() {
  const initialWidth = window.innerWidth;
  const [hasResized, setHasResized] = useState(false);

  function onResize() {
    const currentWidth = window.innerWidth;
    const breakPointSm = parseInt(style.screenSm, 10);

    if ((initialWidth > breakPointSm && currentWidth <= breakPointSm) || (initialWidth <= breakPointSm && currentWidth > breakPointSm)) {
      setHasResized(true);
    } else {
      setHasResized(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  if (ScreenUtil.isMobile()) {
    return false;
  }
  return hasResized;
}
