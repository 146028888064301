import axios from "axios";
import { ReferenceService, URLService, DebugService } from "services";

class CreativeService {
  resourceUrl = URLService.getResourceURL();

  apiUrl = URLService.getApiURL();

  async getCreativeData(creativeId: string) {
    const response = await axios.get(this.apiUrl + creativeId, { timeout: 5000 });
    return response?.data;
  }

  getPublisherPath(publisherId: string | null) {
    return this.resourceUrl + (publisherId === null ? ReferenceService.constants.DEFAULT_DIRECTORY_NAME : publisherId);
  }

  async getContentTop(type: string, publisherId: string = ReferenceService.constants.DEFAULT_DIRECTORY_NAME) {
    return this.getImage(publisherId, ReferenceService.constants.CONTENT_TOP_FILE_NAME, type);
  }

  async getContentBottom(type: string, publisherId: string = ReferenceService.constants.DEFAULT_DIRECTORY_NAME) {
    return this.getImage(publisherId, ReferenceService.constants.CONTENT_BOTTOM_FILE_NAME, type);
  }

  async getContent(type: string, publisherId: string = ReferenceService.constants.DEFAULT_DIRECTORY_NAME) {
    return this.getImage(publisherId, ReferenceService.constants.CONTENT_FILE_NAME, type);
  }

  async getNavigationBar(type: string, publisherId: string = ReferenceService.constants.DEFAULT_DIRECTORY_NAME) {
    return this.getImage(publisherId, ReferenceService.constants.NAVIGATIONBAR_FILE_NAME, type);
  }

  // @ts-ignore
  getImage(publisherId: string, fileName: string, type: string) {
    const url = `${this.getPublisherPath(publisherId)}/${fileName}-${type}.jpg`;
    DebugService.info(`Fetching image with URL ${url}`);
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        resolve(url);
      };
      image.onerror = () => reject(new Error(`The ${fileName} resource at URL "${url}" failed to load.`));
    })
      .then((finalUrl) => finalUrl)
      .catch((error) => {
        if (publisherId !== ReferenceService.constants.DEFAULT_DIRECTORY_NAME) {
          return this.getImage(ReferenceService.constants.DEFAULT_DIRECTORY_NAME, fileName, type);
        }
        throw error;
      });
  }
}

export default new CreativeService();
