import { datadogRum, RumErrorEvent, RumEvent } from "@datadog/browser-rum";

export type ErrorMonitoringConfig = {
  applicationId?: string;
  clientToken?: string;
  env?: string;
  version?: string;
};

export const shouldDiscardEvent = (event: RumEvent) => {
  if (!event.error) {
    return false;
  }

  const errorMessage = (event as RumErrorEvent).error.message;
  const exceptions = [
    'Uncaught "Object Not Found Matching Id',
    "Script error.",
    'Uncaught "Could not find prebidjs in window"', // third party
    "Cannot read properties of undefined (reading 'consoleLog')", // chrome extension issue
    "__sherpa is not defined", // chrome extension issue
  ];

  if (exceptions.findIndex((exception) => errorMessage.indexOf(exception) >= 0) >= 0) {
    return true;
  }

  return false;
};

class ErrorMonitoringService {
  init(config: ErrorMonitoringConfig) {
    const { applicationId, clientToken, env, version } = config;

    if (!(applicationId && clientToken && env)) {
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: "datadoghq.eu",
      service: "ocs-demo",
      env,
      version,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "allow",
      beforeSend: (event: RumEvent) => !shouldDiscardEvent(event),
    });

    datadogRum.startSessionReplayRecording();
  }
}

export default ErrorMonitoringService;
