import { DesignSystemProvider } from "@ogury/design-system";
import * as React from "react";

function AppProvider({ children }: React.PropsWithChildren) {
  // Provide here the list of all the global context providers so that they will be automatically shared to all environments ie. browser and testing
  // Never include BrowserRouter as testing environment needs to use MemoryRouter
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <DesignSystemProvider>{children}</DesignSystemProvider>;
}

export default AppProvider;
