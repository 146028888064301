import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { DebugService } from "services";
import ErrorImage from "assets/img/ErrorImg.svg";
import { Row, Typography } from "components";
import { ErrorDataProps } from "types";

import style from "./Error.module.scss";

export default function Error({ error }: { error: ErrorDataProps }) {
  const { t } = useTranslation();

  useEffect(() => {
    DebugService.error(`Error page with error type "${error.type}" has been thrown.`);
  }, []);

  function renderImage() {
    return <img alt="Error" src={ErrorImage} />;
  }

  return (
    <div className={style.container}>
      {renderImage()}
      <Row top={32}>
        <Typography variant="body1BoldBis">{t(`DemoShowcase.${error.type}` ?? "DemoShowcase.pageNotFound", "Page not found")}</Typography>
      </Row>
      <Row top={8}>
        <Typography variant="body1variant">
          {t(`DemoShowcase.${error.type}Text` ?? "DemoShowcase.pageNotFoundText", "We can't seem to find the page you are looking for.")}
          {error.message && ` ${error.message}`}
        </Typography>
      </Row>
    </div>
  );
}
