import { ReactElement } from "react";

import styles from "./Loader.module.css";

function Loader(): ReactElement {
  return (
    <div className={styles.loader}>
      <div className={styles.bar} />
    </div>
  );
}
export default Loader;
