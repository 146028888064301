import { useEffect, useState } from "react";

export default function useAdBlockerDetection() {
  const [adBlockerActive, setAdBlockerActive] = useState(false);

  function isVisible(element: Element) {
    const elementStyle = window.getComputedStyle(element);
    return elementStyle.width !== "0" && elementStyle.height !== "0" && elementStyle.opacity !== "0" && elementStyle.display !== "none" && elementStyle.visibility !== "hidden";
  }

  async function load() {
    const adElement = document.getElementById("adTester");
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (adElement && isVisible(adElement)) {
      setAdBlockerActive(false);
    } else {
      setAdBlockerActive(true);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return adBlockerActive;
}
