import { ReactElement, useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import i18n from "i18n/config";
import { DemoPage, ErrorPage } from "app/Pages";
import { ReferenceService } from "services";
import "assets/style/app.scss";
import useIsCorrectOrientation from "./hooks/useIsCorrectOrientation";
import useResizeDetection from "./hooks/useResizeDetection";

const DEMO_ROUTE = "/page";

function RedirectToDemo() {
  const location = useLocation();
  return <Navigate to={`${DEMO_ROUTE}/${location.search}`} />;
}

function App(): ReactElement {
  const resizeError = useResizeDetection();
  const correctOrientation = useIsCorrectOrientation();
  const [previewParams] = useSearchParams();

  const forcePreviewParam = previewParams.get(ReferenceService.urlParameters.FORCE_PREVIEW);

  useEffect(() => {
    i18n.changeLanguage("en-US");
  }, []);

  if (!correctOrientation && !forcePreviewParam) {
    return <ErrorPage error={{ type: ReferenceService.errorType.WRONG_ORIENTATION }} />;
  }

  if (resizeError) {
    return <ErrorPage error={{ type: ReferenceService.errorType.PAGE_RESIZED }} />;
  }

  if (!window.navigator.onLine) {
    return <ErrorPage error={{ type: ReferenceService.errorType.NO_INTERNET }} />;
  }

  if (window.navigator.userAgent.includes("Firefox")) {
    return <ErrorPage error={{ type: ReferenceService.errorType.UNSUPPORTED_BROWSER }} />;
  }

  return (
    <Routes>
      <Route path="/preview/*" element={<RedirectToDemo />} />
      <Route path={DEMO_ROUTE} element={<DemoPage forcePreview={!!forcePreviewParam} />} />
      <Route path="/*" element={<ErrorPage error={{ type: ReferenceService.errorType.URL_WRONG }} />} />
    </Routes>
  );
}

export default App;
