import { ReactElement, ReactNode } from "react";
// import styles from "./index.module.css";

type RowProps = {
  children: ReactNode;
  top?: number;
  padding?: number;
  className?: string;
};

function Row({ children, top = 0, padding = 0, className }: RowProps): ReactElement {
  return (
    <div style={{ marginTop: top, padding }} className={className}>
      {children}
    </div>
  );
}
export default Row;
