import { CreativeDataProps } from "types/Types";
import IphoneShell from "assets/img/IphoneShell.png";
import style from "./RightPanel.module.scss";
import { Creative } from "./components";
import { deviceType } from "../../../../../services/ReferenceService/ReferenceService";

type RightPanelProps = {
  creativeData: CreativeDataProps;
  device: string;
  forcePreview?: boolean;
};

function RightPanel({ device, creativeData, forcePreview }: RightPanelProps) {
  const creativeProperties = {
    device,
    creativeData,
    forcePreview,
  };

  return (
    <div className={style.rightContainer}>
      {device === deviceType.MOBILE && !forcePreview ? (
        <div className={style.phone}>
          <img className={style.iphoneShell} id="iphoneShell" src={IphoneShell} alt="Iphone Shell" />
          <div className={style.phoneContent}>
            <Creative {...creativeProperties} />
          </div>
        </div>
      ) : (
        <Creative {...creativeProperties} />
      )}
    </div>
  );
}

export default RightPanel;
