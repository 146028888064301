import { ScreenUtil } from "utils";
import { useEffect, useState } from "react";

export default function useIsCorrectOrientation() {
  const isMobile = ScreenUtil.isMobile();

  const [isCorrect, setIsCorrect] = useState(!(isMobile && window.innerWidth > window.innerHeight));

  function onResize() {
    setIsCorrect(!(isMobile && window.innerWidth > window.innerHeight));
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return isCorrect;
}
