import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { URLService } from "services";
import OguryLogo from "assets/img/Logo.svg";
import { Divider, Link, Row, Typography } from "components";
import { OptionType, Select } from "@ogury/design-system";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";
import MacLineIcon from "remixicon-react/MacLineIcon";

import style from "./LeftPanel.module.scss";
import { deviceType } from "../../../../../services/ReferenceService/ReferenceService";

type LeftPanelProps = {
  creativeType: string | undefined;
  advertiserName: string;
  device: string;
  // eslint-disable-next-line no-unused-vars
  onDeviceChange: (device: string) => void;
};

function LeftPanel({ device, creativeType, advertiserName, onDeviceChange }: LeftPanelProps): ReactElement {
  const { t } = useTranslation();
  const allParams = window.location.href.split("?");

  const optionDeviceRenderer = ({ value, label }: OptionType): ReactElement => (
    <div className={style.viewMenuItem}>
      {value === deviceType.MOBILE ? (
        <>
          <SmartphoneLineIcon size={16} /> {label}
        </>
      ) : (
        <>
          <MacLineIcon size={16} /> {label}
        </>
      )}
    </div>
  );

  const hasDesktopPreview = useMemo(() => {
    if (creativeType?.trim().startsWith("Thumbnail")) {
      return true;
    }

    if (creativeType?.trim().startsWith("Banner")) {
      return true;
    }

    return false;
  }, [creativeType]);

  return (
    <div className={style.container}>
      <div>
        <Row>
          <img id="logo" src={OguryLogo} alt="Ogury logo" />
        </Row>
        <Row top={40}>
          <Typography variant="body1">{t("DemoShowcase.advertiser", "Advertiser")}</Typography>
        </Row>
        <Row className={style.breakNewLine}>
          <Typography variant="body1Bold">{advertiserName}</Typography>
        </Row>
        <Row top={16}>
          <Divider />
        </Row>
        <Row top={16}>
          <Typography variant="body1">{t("DemoShowcase.adFormat", "Ad Format")}</Typography>
        </Row>
        <Row>
          <Typography variant="body1Bold">{creativeType}</Typography>
        </Row>

        <Row top={16}>
          <Divider />
        </Row>
        <Row top={16}>
          <Select
            width="100%"
            onChange={onDeviceChange}
            value={device}
            disabled={!hasDesktopPreview}
            optionRenderer={optionDeviceRenderer}
            options={[
              {
                label: "Mobile",
                value: deviceType.MOBILE,
              },
              {
                label: "Desktop",
                value: deviceType.DESKTOP,
              },
            ]}
          />
        </Row>
        <Row top={40} padding={3} className={style.QRcodeBorder}>
          <QRCode value={`${URLService.getPublicURL()}?${allParams[1]}`} bgColor="#FFFFFF" fgColor="#000000" size={140} />
        </Row>
        <Row>
          <Typography variant="body2">{t("DemoShowcase.seeMoreOnPhone", "See this demo on your phone")}</Typography>
        </Row>
      </div>
      <Row>
        <Link href="https://www.ogury.com" text={t("DemoShowcase.learMoreAboutOgury", "Learn more about Ogury Ads")} />
      </Row>
    </div>
  );
}
export default LeftPanel;
