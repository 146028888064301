import { EnvService, ENV_VARIABLE } from "services";
import Package from "../../../package.json";

function customLog(text: string, backgroundColor: string) {
  // eslint-disable-next-line
  return console.log("%c DEMO SHOWCASE ", `font-size : 10px; background: ${backgroundColor} ; color: #FFF; border-radius : 5px; padding : 2px 0;`, text);
}

class DebugService {
  constructor() {
    if (this.shouldLog()) {
      this.info(`App initialized with environment "${ENV_VARIABLE}"`);
      this.info(`App version "${Package.version}"`);
    }
  }

  shouldLog() {
    if (process.env.NODE_ENV === "test") return false;
    return !EnvService.isProduction() || window.location?.search?.includes("debug=true");
  }

  log(message: string, style: string) {
    if (this.shouldLog()) {
      customLog(message, style);
    }
  }

  info(message: string) {
    this.log(message, "#00759a");
  }

  error(message: string) {
    this.log(message, "red");
  }
}

export default new DebugService();
